import { useLazyQuery, useMutation } from "@apollo/client";
import { SnapDropdownCustomEvent } from "@snap-mobile/snap-ui/dist/types/components";
import {
  DropdownItemSelected,
  DropdownMenuItem,
} from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
import { SpendGroupRoster, SpendSortInput } from "graphql/generated";
import { RESEND_USER_INVITE } from "graphql/mutations/invite";
import { EXPORT_USERS_EMAILS } from "graphql/queries/user";
import { HandleAllCheckboxAction } from "helpers/all-checkbox-action";
import { useContextStrict } from "helpers/context-strict";
import { exportToCsv } from "helpers/export-csv";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import { isPsPublished } from "helpers/status-text";
import useModal from "hooks/use-modal";
import React, { useEffect, useState } from "react";
import SendEmail from "shared-components/modal/send-email";
import { SpinnerContainer } from "shared-components/spinner";
import NoResults from "shared-components/table/no-results";
import SortIcon from "shared-components/table/sort-icon";
import {
  SnapBadge,
  SnapCheckboxButton,
  SnapDropDown,
  SnapLink,
  SnapTable,
} from "suit";
import { SpendRosterChecked } from "types/group-roster";
type TableProps = {
  participants: SpendRosterChecked[];
  handleNavigate: (userId: string) => void;
  handleGroupNavigate: (groupId: string, seasonId: string) => void;
  handleCheckboxAction: (idx: number) => void;
  setParticipants: (userArray: SpendRosterChecked[]) => void;
  isNameBoxChecked: boolean;
  setNameBoxChecked: (value: boolean) => void;
  sort: SpendSortInput | undefined;
  toggleSort: (sortValue: string) => void;
  canEditParticipants: boolean;
  handleNavToReportingInvoices: (groupRosterId: string, status: string) => void;
  setSelectedItems: React.Dispatch<React.SetStateAction<SpendRosterChecked[]>>;
  selectedItems: SpendRosterChecked[];
  loading: boolean;
  getInviteStatusAndOptionCheck: (
    inviteStatus: string | undefined | null,
    inviteExpiresAt: string | undefined | null,
    groupRosterStatus: string | undefined | null
  ) => { status: string; addOption: boolean };
};

function Table({
  participants,
  handleNavigate,
  handleCheckboxAction,
  setParticipants,
  isNameBoxChecked,
  setNameBoxChecked,
  sort,
  toggleSort,
  handleGroupNavigate,
  canEditParticipants,
  handleNavToReportingInvoices,
  setSelectedItems,
  selectedItems,
  loading,
  getInviteStatusAndOptionCheck,
}: TableProps) {
  const groupContext = useContextStrict(GroupContext);
  const toast = useContextStrict(ToastContext);
  const paymentSchedulePublished = groupContext?.groups
    ?.filter((group) =>
      isPsPublished(group.latestSeason?.paymentScheduleStatus)
    )
    .map((group) => {
      return group.name;
    });
  const { isOpen: sendEmailSingleOpen, toggle: sendEmailSingleToggle } =
    useModal();
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [
    reSendInvites,
    {
      loading: loadingResendInvites,
      data: resendInviteData,
      error: resendInviteError,
    },
  ] = useMutation(RESEND_USER_INVITE, {
    refetchQueries: ["SpendRostersFiltered"],
  });

  useEffect(() => {
    if (
      !loadingResendInvites &&
      resendInviteData &&
      resendInviteData.spendUserInviteResend
    ) {
      toast.setToast({
        message: "Invite has been sent",
        type: "success",
      });
    }
    if (!loadingResendInvites && resendInviteError) {
      toast.setToast({
        message: "There were errors sending invites",
        type: "danger",
      });
    }
    // eslint-disable-next-line
  }, [loadingResendInvites, resendInviteData, resendInviteError]);

  const handleCheckActiveUserForResend = (
    item: SpendGroupRoster,
    participant: SpendRosterChecked
  ) => {
    reSendInvites({
      variables: {
        input: {
          id: item.inviteId,
          email: participant.email,
          groupId: item.groupId,
          userId: item.userId,
          groupRosterId: item.id,
          seasonId: item.seasonId,
        },
      },
    });
    toast.setToast({
      message: "Sending Invites",
      type: "warning",
    });
  };
  const menuItems: DropdownMenuItem[] = [
    {
      text: "Send Email",
      name: "Send Email",
      value: "Send Email",
    },

    {
      name: "Export Address",
      text: "Export Address",
      value: "Export Address",
    },
  ];

  const [
    ExportEmails,
    { loading: loadingExportedEmails, data: exportEmailsData },
  ] = useLazyQuery(EXPORT_USERS_EMAILS);

  const handleExport = (emails: string[]) => {
    ExportEmails({
      variables: {
        input: {
          emails: emails,
        },
      },
    });
  };
  const menuAction = (
    e: SnapDropdownCustomEvent<DropdownItemSelected>,
    participant: SpendRosterChecked,
    gr: SpendGroupRoster
  ) => {
    let value = e.target.value;
    if (value === "Send Email") {
      setSelectedUser([participant]);
      sendEmailSingleToggle();
    } else if (value === "Resend Invite") {
      handleCheckActiveUserForResend(gr!, participant);
    } else {
      handleExport([participant.email ?? ""]);
    }
  };

  useEffect(() => {
    if (exportEmailsData && exportEmailsData.spendUserExportEmails) {
      exportToCsv(exportEmailsData.spendUserExportEmails);
    }
  }, [loadingExportedEmails, exportEmailsData]);
  return (
    <>
      <SnapTable>
        <table className="ui celled hidden lg:table">
          <thead>
            <tr>
              {canEditParticipants && (
                <th>
                  <SnapCheckboxButton
                    checked={isNameBoxChecked}
                    onClick={(e) => {
                      setNameBoxChecked(e.currentTarget.checked);
                      HandleAllCheckboxAction(
                        isNameBoxChecked,
                        participants,
                        setParticipants,
                        setSelectedItems,
                        selectedItems
                      );
                    }}
                  />
                </th>
              )}
              <th>
                Name
                <SortIcon
                  field={"name"}
                  currentSort={sort}
                  toggleSort={toggleSort}
                />
              </th>
              <th>
                Groups
                <SortIcon
                  field={"seasonDate"}
                  currentSort={sort}
                  toggleSort={toggleSort}
                />
              </th>
              <th className="text-right">Status</th>
              <th className="text-right pr-4">
                Paid
                <SortIcon
                  field={"paid"}
                  currentSort={sort}
                  toggleSort={toggleSort}
                />
              </th>
              <th className="text-right">Processing</th>
              <th className="text-right pr-4">
                Upcoming
                <SortIcon
                  field={"upcoming"}
                  currentSort={sort}
                  toggleSort={toggleSort}
                />
              </th>
              <th className="text-right pr-4">
                Past Due
                <SortIcon
                  field={"pastDue"}
                  currentSort={sort}
                  toggleSort={toggleSort}
                />
              </th>
              <th className="text-right pr-5 max-w-[60px]">
                Days Past
                <SortIcon
                  field={"pastDueDays"}
                  currentSort={sort}
                  toggleSort={toggleSort}
                />
              </th>
              {canEditParticipants && <th></th>}
            </tr>
          </thead>
          <tbody>
            <SpinnerContainer loading={loading} inTable={true} colSpan={10} />
            {!loading &&
              participants.map((participant, idx) => {
                const rowSpan = participant.groupRosters?.length || 1;
                const isEven = idx % 2 === 0;
                const columnStyles = {
                  backgroundColor: isEven ? "#F8FAFC" : "#fff",
                };
                return participant.groupRosters?.map((gr, grIdx) => {
                  let options = [...menuItems];
                  let canResendInvite = paymentSchedulePublished?.includes(
                    gr?.group?.name
                  );

                  const { status, addOption } = getInviteStatusAndOptionCheck(
                    gr?.invite?.status,
                    gr?.invite?.expiresAt,
                    gr?.status
                  );

                  if (canResendInvite && addOption) {
                    options.splice(1, 0, {
                      name: "Resend Invite",
                      text: "Resend Invite",
                      value: "Resend Invite",
                    });
                  }
                  const season = gr?.group?.seasons?.find(
                    (season) => season?.id === gr.seasonId
                  );
                  return (
                    <tr key={`${gr?.id}`}>
                      {grIdx === 0 && canEditParticipants && (
                        <td
                          className="checkbox-cell"
                          rowSpan={rowSpan}
                          style={columnStyles}
                        >
                          <SnapCheckboxButton
                            checked={participant.isChecked}
                            onClick={() => {
                              handleCheckboxAction(idx);
                            }}
                          />
                        </td>
                      )}
                      {grIdx === 0 && (
                        <td rowSpan={rowSpan} style={columnStyles}>
                          <SnapLink
                            href={`/people/participants/${participant.id}`}
                            className="text-sm text-blue-600 cursor-pointer"
                            sr-only="srOnly"
                            onClick={(e) => {
                              e.preventDefault();
                              groupContext.setParticipant(
                                participant.name ?? ""
                              ); // this is not ideal, should have context for title and use DisplayContext
                              handleNavigate(participant.id ?? "");
                            }}
                          >
                            {participant.name}
                          </SnapLink>
                        </td>
                      )}
                      <td style={columnStyles}>
                        <div
                          className={`mt-2 ${
                            grIdx === rowSpan - 1 ? "mb-3" : ""
                          }`}
                        >
                          <p
                            onClick={() => {
                              handleGroupNavigate(
                                gr?.groupId ?? "",
                                gr?.seasonId ?? ""
                              );
                            }}
                            className="mt-1 font-semibold text-blue-500 cursor-pointer"
                          >
                            {gr?.group?.name}
                          </p>
                          <p className="text-xs leading-tight mt-1 text-gray-500">
                            {season?.name ||
                              `${FormatDate(
                                season?.startDateAt ?? "",
                                DateFormatSupported.NumbersLight
                              )} - ${FormatDate(
                                season?.endDateAt ?? "",
                                DateFormatSupported.NumbersLight
                              )}`}
                          </p>
                        </div>
                      </td>
                      <td
                        className={"flex justify-end items-center"}
                        style={columnStyles}
                      >
                        <span className="whitespace-nowrap">
                          <SnapBadge
                            className="flex capitalize"
                            color={getBadgeColor(status ?? "")}
                          >
                            {status}
                          </SnapBadge>
                        </span>
                      </td>
                      <td className={"numeric-cell"} style={columnStyles}>
                        <p
                          key={`Invoice_Total_Paid_${idx}`}
                          className={`${
                            gr?.total?.paid
                              ? "text-green-600 cursor-pointer"
                              : ""
                          }`}
                          onClick={() =>
                            handleNavToReportingInvoices(gr?.id ?? "", "paid")
                          }
                        >
                          {FormatMoney(gr?.total?.paid || 0)}
                        </p>
                      </td>
                      <td className={"numeric-cell"} style={columnStyles}>
                        <p className="">
                          {FormatMoney(gr?.total?.processing || 0)}
                        </p>
                      </td>
                      <td className={"numeric-cell"} style={columnStyles}>
                        <p
                          className={`${
                            gr?.total?.upcoming
                              ? "text-blue-600 cursor-pointer"
                              : ""
                          }`}
                          onClick={() =>
                            handleNavToReportingInvoices(
                              gr?.id ?? "",
                              "upcoming"
                            )
                          }
                        >
                          {FormatMoney(gr?.total?.upcoming || 0)}
                        </p>
                      </td>
                      <td className={"numeric-cell"} style={columnStyles}>
                        <p
                          className={`${
                            gr?.total?.pastDue
                              ? "text-red-600 cursor-pointer"
                              : ""
                          }`}
                          onClick={() =>
                            handleNavToReportingInvoices(
                              gr?.id ?? "",
                              "past_due"
                            )
                          }
                        >
                          {FormatMoney(gr?.total?.pastDue || 0)}
                        </p>
                      </td>
                      <td
                        className={"numeric-cell max-w-[80px]"}
                        style={columnStyles}
                      >
                        <p className="flex justify-end pl-14 first:pt-0 pt-8">
                          {gr?.total?.pastDueDays || 0}
                        </p>
                      </td>
                      {canEditParticipants && (
                        <td className={"numeric-cell"} style={columnStyles}>
                          <SnapDropDown
                            options={options}
                            minimal
                            onSnap-dropdown-item-selected={(e) => {
                              menuAction(e, participant, gr!);
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  );
                });
              })}
            {participants.length === 0 && (
              <NoResults loading={!loading} inTable={true} colSpan={8} />
            )}
          </tbody>
        </table>
      </SnapTable>
      {sendEmailSingleOpen && (
        <SendEmail
          sendEmailOpen={sendEmailSingleOpen}
          sendEmailToggle={sendEmailSingleToggle}
          selectedItems={selectedUser}
          role="Participants"
        />
      )}
    </>
  );
}

export default Table;
