import {
  SpendBankTransaction,
  SpendBankTransactionDetail,
  SpendLegacyTransaction,
} from "graphql/generated";
import {
  legacyPaymentMethods,
  legacyTransactionTypes,
} from "types/transactions";
import { MapAndCalcSum } from "./map-and-reduce";

export const getTransactionType = (
  transaction:
    | SpendBankTransaction
    | SpendBankTransactionDetail
    | SpendLegacyTransaction
) => {
  let transactionType: string | null;
  if (transaction.__typename === "SpendLegacyTransaction") {
    transactionType = "legacy";
  } else {
    transactionType =
      (transaction as SpendBankTransaction | SpendBankTransactionDetail)
        .transactionType || null;
  }
  const paymentType =
    transaction.metadata?.tags?.spendPaymentType ||
    transactionType ||
    transaction.type;
  switch (paymentType?.toLocaleLowerCase()) {
    case "disputeexternaltransfer":
      return "Dispute";
    case "parentpayment":
      return "Invoice Payment";
    case "refund":
    case "invoice_refund":
      return "Invoice Refund";
    case "externaltransfer":
      return transaction.direction?.toLowerCase() === "debit"
        ? "Transfer Out"
        : "Transfer In";
    case "internaltransfer":
      return transaction.direction?.toLowerCase() === "debit"
        ? "Transfer Out"
        : "Transfer In";
    case "checkdeposit":
    case "deposit":
      return "Check Deposit";
    case "checkpayment":
      return "Check Payment";
    case "debit_card":
      return "Debit Card";
    case "receivedachtransaction":
      return "ACH";
    case "credit_memo":
      return "Credit Memo";
    case "fee":
      return "Fee";
    default:
      return (
        paymentType ||
        getPaymentMethod(transaction.processor, transactionType || "")
      );
  }
};
// amount is from text, so its dollar format
export const calculateFee = (amount: string, feePercent?: number | null) => {
  const amountNum = +(amount || "0") * 100; // convert to pennies
  const feePercentValue = feePercent || 0;
  const feeCalc = Math.ceil(amountNum * feePercentValue);
  return (feeCalc / 100).toFixed(2);
};
export const getTransactionTitleDeprecated = (
  tr: SpendBankTransaction | SpendBankTransactionDetail
) => {
  const name = () => {
    if (tr.metadata?.summary) return tr.metadata?.summary;
    if (
      tr.metadata?.addenda &&
      tr.metadata?.description &&
      tr.metadata?.description !== tr.metadata.addenda
    ) {
      return `${tr.metadata?.description} | ${tr.metadata?.addenda}`;
    }
    return tr.metadata?.description || "Transaction";
  };
  return `${name()} #${tr.id?.slice(-5)}`;
};

export const getPaymentMethod = (
  processor: string | null | undefined,
  type: string | null
) => {
  if (type === "Sent Check") return "Check Payment";
  return processor === "stripe" ? "Card" : "ACH";
};
export const getTransactionTitle = (transaction: SpendBankTransaction) => {
  const paymentType =
    transaction.metadata?.tags?.spendPaymentType ||
    transaction.transactionType ||
    transaction.type;
  const transactionDestinationId = transaction.destination;
  const transactionSourceId = transaction.source;
  const playerName =
    transaction.groupRoster?.roster?.name ||
    transaction.external?.playerName ||
    "Unknown Player";
  const groupName =
    transaction.groupRoster?.group?.name ||
    transaction.external?.groupName ||
    transaction.destination;
  const invoiceDescription = transaction.external?.invoiceDescription || "-";
  if (transaction.metadata?.tags?.disputeId) {
    return `Purchase Dispute ${transaction.metadata?.tags?.disputeId}`;
  }
  switch (paymentType?.toLowerCase()) {
    case "disputeexternaltransfer":
      const disputeTitle = `${transaction.external?.playerName} | Dispute`;
      return disputeTitle;
    case "parentpayment":
      const paymentTitle = `${playerName} | ${groupName}`;
      if (transaction.transactionStatus === "Returned") {
        return `Return of: ${paymentTitle}`;
      }
      return paymentTitle;
    case "refund":
    case "invoice_refund":
      return `${invoiceDescription} | ${playerName} | ${groupName}`;
    case "externaltransfer":
      if (transaction.transactionStatus === "Returned") {
        return transaction.direction?.toLowerCase() === "debit"
          ? `Return of: External Transfer From ${
              transaction.metadata?.tags?.spendSourceId ?? "Bank"
            }`
          : `Return of: External Transfer To ${
              transaction.metadata?.tags?.spendDestinationId ?? "Bank"
            }`;
      } else {
        return transaction.direction?.toLowerCase() === "credit"
          ? `External Transfer From ${
              transaction.metadata?.tags?.spendSourceId ?? "Bank"
            }`
          : `External Transfer To ${
              transaction.metadata?.tags?.spendDestinationId ?? "Bank"
            }`;
      }
    case "internaltransfer":
      if (transaction.direction?.toLowerCase() === "credit") {
        return `Transfer From ${transactionSourceId}`;
      } else {
        return `Transfer To ${transactionDestinationId}`;
      }
    case "checkpayment":
      return `Check Sent To ${
        transaction.payee?.payeeName ??
        transaction.metadata?.tags?.spendDestinationId
      }`;
    case "checkdeposit":
    case "deposit":
      return "Check Deposit";
    case "receivedachtransaction":
    case "cardtransaction":
    case "debit_card":
    case "authorization":
    case "adjustmenttransaction":
    case "wiretransaction":
      return transaction.metadata?.summary || "Card Transaction";
    case "credit_memo":
      return transaction.creditMemo?.title ?? "";
    case "ach":
      return `External Transfer To ${transaction.metadata?.summary}`;
    case "fee":
      return `${transaction.metadata?.summary}`;
    default:
      return (
        paymentType ||
        transaction.metadata?.summary ||
        "Unclassified Transaction"
      );
  }
};

const getLegacyTransactionType = (
  transaction: SpendLegacyTransaction,
  legacyAccountId: string
) => {
  const preType = transaction.type?.replace("_", " ") ?? "Unknown";
  if (preType === "transfer") {
    if (transaction.source === legacyAccountId) {
      return "transfer out";
    } else {
      return "transfer in";
    }
  }
  return transaction.type?.replace("_", " ") ?? "Unknown";
};
const getLegacyDescription = (
  transaction: SpendLegacyTransaction,
  legacyAccountId: string
) => {
  if (transaction.reconciliation?.invoiceTransactions?.length) {
    const multiInvoice =
      transaction.reconciliation.invoiceTransactions.length > 1;
    const rosterName =
      transaction.reconciliation.invoiceTransactions.at(0)?.rosterName;
    let invoiceDesc =
      transaction.reconciliation.reconciledTo?.at(0)?.description || "Invoice";
    if (multiInvoice) {
      invoiceDesc = "Multiple Invoices";
    }
    const groupName =
      transaction.reconciliation.reconciledTo?.at(0)?.groupName || null;
    return `${invoiceDesc} | ${rosterName}${groupName ? "| " + groupName : ""}`;
  }
  if (
    transaction.type === legacyTransactionTypes.DEPOSIT &&
    transaction.destination === legacyAccountId
  ) {
    const method = transaction.metadata.method;
    let depositDescription = "";
    switch (method) {
      case "rdc":
        depositDescription = "Mobile Deposit";
        break;
      case "cash":
        depositDescription = "Cash Deposit";
        break;
      default:
        depositDescription = "Deposit";
    }
    return `${depositDescription} into ${transaction.metadata.destinationName}`;
  } else if (transaction.type === legacyTransactionTypes.FEE) {
    return transaction.transactionNote;
  } else if (transaction.type === legacyTransactionTypes.TRANSFER) {
    return `Transfer from ${transaction.metadata.sourceName} to ${transaction.metadata.destinationName}`;
  } else if (transaction.type === legacyTransactionTypes.BILL_PAY) {
    const checkNo = transaction.metadata.checkNo || "n/a";
    return `${transaction.metadata.destinationName} | Check #(${checkNo})`;
  } else if (transaction.type === legacyTransactionTypes.ACH) {
    if (transaction.description) {
      return transaction.description;
    } else if (transaction.source === legacyAccountId) {
      return `ACH Transfer to ${transaction.metadata.destinationName}`;
    } else {
      return `ACH Transfer from ${transaction.metadata.sourceName}`;
    }
  } else if (
    transaction.source === legacyAccountId &&
    [
      legacyPaymentMethods.CHECK_RECIPIENT,
      legacyPaymentMethods.DEPOSIT,
      legacyPaymentMethods.MERCHANT,
    ].includes(transaction.metadata.sourcePaymentMethod)
  ) {
    //todo check reconcileData
    if (transaction.destination) {
      return transaction.metadata.destinationName;
    } else if (transaction.type === legacyTransactionTypes.WITHDRAWAL) {
      const method = transaction.metadata.method;
      const merchant = transaction.metadata.merchant
        ? ` | ${transaction.metadata.merchant}`
        : "";
      return `${method} ${transaction.type}${merchant}`;
    } else if (transaction.metadata.merchant) {
      return transaction.metadata.merchant;
    }
    return transaction.description || transaction.metadata.spendDestination;
  } else {
    //todo check reconcileData
    if (transaction.source) {
      return transaction.metadata.sourceName;
    } else if (transaction.type === legacyTransactionTypes.DEPOSIT) {
      const method =
        transaction.metadata.method === "rdc"
          ? `Mobile`
          : transaction.metadata.method;
      const merchant = transaction.metadata.merchant
        ? ` | ${transaction.metadata.merchant}`
        : "";
      return `${method} ${transaction.type}${merchant}`;
    } else if (transaction.metadata.merchant) {
      return transaction.metadata.merchant;
    }
    return (
      transaction.description ||
      `${transaction.type} | from ${transaction.metadata.sourceName} to ${transaction.metadata.destinationName}`
    );
  }
};
const getLegacyStatus = (transaction: SpendLegacyTransaction) => {
  return transaction.status === "SETTLED_RETURNED"
    ? "RETURNED"
    : transaction.status;
};
const getReconciledStatus = (transaction: SpendLegacyTransaction) => {
  const reconPossible = Math.abs(
    transaction.reconciliation?.amount ||
      (transaction.amount || 0) - (transaction.snapAmount || 0)
  );
  const totalRecon = Math.abs(
    MapAndCalcSum(
      transaction.reconciliation?.budgetTransactions || [],
      "amount"
    )
  );
  let status = "Unreconciled";
  if (totalRecon !== 0) {
    if (reconPossible > totalRecon) {
      status = "Partially Reconciled";
    } else {
      status = "Reconciled";
    }
  }
  let reconciledType: string | undefined = undefined;

  if (transaction.reconciliation) {
    if (transaction.reconciliation.invoiceTransactions?.length) {
      reconciledType = "Invoice";
    } else if (
      transaction.reconciliation.budgetTransactions?.length &&
      transaction.reconciliation.type
    ) {
      const t = transaction.reconciliation.type;
      if (t === "income") {
        reconciledType = "Reconciled Budget Income";
      } else {
        reconciledType = "Reconciled Budget Expense;";
      }
    }
  }
  return {
    status,
    reconcilable: reconPossible,
    reconciledAmount: totalRecon,
    reconciledType,
  };
};
const getLegacyDirection = (
  transaction: SpendLegacyTransaction,
  legacyAccountId: string
) => {
  if (transaction.source === legacyAccountId) {
    return "debit";
  }
  return "credit";
};
export const legacyTrx = {
  getType: getLegacyTransactionType,
  getTitle: getLegacyDescription,
  getStatus: getLegacyStatus,
  getReconStatus: getReconciledStatus,
  getDirection: getLegacyDirection,
};
