import { useLazyQuery, useMutation } from "@apollo/client";
import DisplayContext from "context/display-context";
import SeasonContext from "context/season-context";
import { PaymentScheduleStatus } from "graphql/generated";
import { RESEND_USER_INVITE } from "graphql/mutations/invite";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
import { EXPORT_USERS_EMAILS } from "graphql/queries/user";
import { exportToCsv } from "helpers/export-csv";
import useModal from "hooks/use-modal";
import { ToastType } from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SnapDropDown, SnapIcon, SnapIndicator } from "suit";
import { SpendRosterResend } from "types/group-roster";
type BulkActionsPlusArchiveAndUnarchiveProps = {
  numOfItemsSelected: number;
  deselectAllAction: () => void;
  sendEmailToggle: () => void;
  unarchiveParticipantToggle: () => void;
  archiveParticipantToggle: () => void;
  selectedItems?: SpendRosterResend[];
  toast: ToastType;
};

function BulkActionsPlusArchiveAndUnarchive({
  numOfItemsSelected,
  deselectAllAction,
  sendEmailToggle,
  unarchiveParticipantToggle,
  archiveParticipantToggle,
  selectedItems,
  toast,
}: BulkActionsPlusArchiveAndUnarchiveProps) {
  const location = useLocation();
  const Season = useContext(SeasonContext);

  // eslint-disable-next-line
  const [canArchive, setCanArchive] = useState<boolean>(true); //Until we need to bring back unarchive
  const [reSendInvitesCollection] = useMutation(RESEND_USER_INVITE, {
    refetchQueries:
      location.pathname === "/groups/collections"
        ? [
            "SpendGroupRostersBySeason",
            {
              query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
              variables: {
                seasonId: Season?.selectedSeason?.id,
                groupIdOrSeasonId: Season?.selectedSeason?.id,
              },
              fetchPolicy: "network-only",
            },
          ]
        : ["SpendRostersFiltered"],
    onCompleted: () => {
      toast.setToast({
        message: "Invite(s) has been sent",
        type: "success",
      });
    },
  });
  const [
    ExportEmails,
    { loading: loadingExportedEmails, data: exportEmailsData },
  ] = useLazyQuery(EXPORT_USERS_EMAILS);
  useEffect(() => {
    if (exportEmailsData && exportEmailsData.spendUserExportEmails) {
      exportToCsv(exportEmailsData.spendUserExportEmails);
    }
  }, [loadingExportedEmails, exportEmailsData]);

  const { isOpen: actionMenuOpen, toggle: actionMenuToggle } = useModal();
  const Display = useContext(DisplayContext);

  const handleCheckActiveUserForResend = () => {
    if (
      location.pathname === "/groups/collections" &&
      Season?.selectedSeason?.paymentScheduleStatus !==
        PaymentScheduleStatus.Published
    ) {
      toast?.setToast({
        message: "Can not send invites while payment schedule is not published",
        type: "warning",
      });
    } else {
      selectedItems?.forEach((user) => {
        reSendInvitesCollection({
          variables: {
            input: {
              id: user.id,
              groupId: user.groupId,
              email: user.email,
              userId: user.userId,
              seasonId: user.seasonId,
              groupRosterId: user.groupRosterId,
            },
          },
        });
      });
      toast.setToast({
        message: "Resending invites - this may take a minute",
        type: "warning",
      });
    }
  };

  const handleExport = (emails: string[]) => {
    ExportEmails({
      variables: {
        input: {
          emails: emails,
        },
      },
    });
  };

  return (
    <div
      className={`${
        actionMenuOpen ? "static lg:fixed" : "fixed"
      }  flex lg:bottom-0 bottom-14 left-0 bg-white border border-t border-black w-full py-4 z-[9991]`}
    >
      <div className="flex mr-auto cursor-pointer items-center lg:ml-80 pl-2 lg:pl-0">
        <SnapIndicator text={numOfItemsSelected} color="gray" size="sm" />
        <p
          className="ml-3 text-blue-600 border-l border-gray-200 pl-4 font-bold"
          onClick={deselectAllAction}
        >
          Deselect All
        </p>
      </div>
      <div className="flex cursor-pointer items-center mr-4">
        {canArchive ? (
          <>
            <SnapIcon icon="user-remove-solid" color="red" size="xs"></SnapIcon>
            <p
              className="ml-2 text-red-600 mr-4 font-bold"
              onClick={archiveParticipantToggle}
            >
              Archive
              {Display?.isDesktop && " from Roster"}
            </p>
          </>
        ) : (
          <>
            <SnapIcon icon="user-add-solid" color="blue" size="xs"></SnapIcon>
            <p
              className="ml-2 text-blue-600 mr-4 font-bold"
              onClick={unarchiveParticipantToggle}
            >
              Unarchive
              {Display?.isDesktop && " from Roster"}
            </p>
          </>
        )}

        <SnapIcon icon="mail-solid" color="#3B82F6" size="xs"></SnapIcon>
        <p
          className="ml-3 text-blue-600 mr-4 font-bold"
          onClick={sendEmailToggle}
        >
          {Display?.isDesktop && "Send "}
          Email
        </p>
        <SnapDropDown
          onClick={actionMenuToggle}
          id="mobile-menu"
          size="lg"
          modalType="drawer"
          minimal
          menuPosition="top"
          options={[
            {
              name: "Resend Invite",
              text: "Resend Invite",
              value: "Resend Invite",
            },
            {
              name: "Export Email Address",
              text: "Export Email Address",
              value: "Export Email Address",
            },
          ]}
          onSnap-dropdown-item-selected={(e) => {
            switch (e.detail.name) {
              case "Resend Invite":
                handleCheckActiveUserForResend();
                break;
              case "Export Email Address":
                let emails: string[] =
                  selectedItems?.map((item) => item.email) ?? [];
                handleExport(emails);
                break;
            }
          }}
        />
      </div>
    </div>
  );
}

export default BulkActionsPlusArchiveAndUnarchive;
