import { useLazyQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import ProgramContext from "context/program-context";
import {
  Maybe,
  SpendDebitCard,
  SpendDebitCardInput,
  SpendInvite,
  SpendOrganization,
} from "graphql/generated";
import { GET_GROUP_DEBIT_CARDS } from "graphql/queries/group";
import { GET_DEBIT_CARD_INVITES } from "graphql/queries/invites";
import { GET_ORGANIZATION_DEBIT_CARDS } from "graphql/queries/organization";
import DebitCardStates from "helpers/debit-card-states";
import useToast from "hooks/use-toast";
import { useCallback, useContext, useEffect, useState } from "react";
import CustomModal, { BtnState } from "shared-components/modal";
import { SpinnerContainer } from "shared-components/spinner";
import ToastMessage from "shared-components/toast-message";
import { SnapInput, SnapRadioButton, SnapSelectMenu } from "suit";
import { debitCardError } from "types/errors";

// has to be string, can't pass through gql
// vgsInput is used as an alias to better target field in VGS vault configuration
const CREATE_ORG_DEBIT_CARD_STRING = `
  mutation SpendOrganizationDebitCardCreate($vgsInput: SpendDebitCardInput!) {
    spendOrganizationDebitCardCreate(input: $vgsInput) {
      id
      approvalId
    }
  }`;

const CREATE_GROUP_DEBIT_CARD_STRING = `
  mutation SpendGroupDebitCardCreate(
    $vgsInput: SpendDebitCardInput!
    $id: String
  ) {
    spendGroupDebitCardCreate(input: $vgsInput, id: $id) {
      id
      approvalId
    }
  }`;

const DOB_INPUT_NAME = "dob-input";

type AssignDebitCardProps = {
  assignDebitCardsOpen: boolean;
  assignDebitCardsToggle: () => void;
  orgInfo: SpendOrganization | undefined;
  groupId?: Maybe<string> | undefined;
  type: "program" | "group";
  cards: SpendDebitCard[];
};

function AssignDebitCard({
  assignDebitCardsOpen,
  assignDebitCardsToggle,
  orgInfo,
  groupId,
  type,
  cards,
}: AssignDebitCardProps) {
  const Program = useContext(ProgramContext);
  const { isToastOpen, toggleToast, ...toast } = useToast();
  const [form, setForm] = useState<VGSForm>();
  const dobRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!assignDebitCardsOpen || form === undefined || node === null) {
        return;
      }

      const maxYear = new Date().getFullYear() - 2018;
      let validYears = "00";
      for (let i = 1; i < maxYear; i++) {
        validYears += `|${i.toString().padStart(2, "0")}`;
      }
      const datePat = `/((19\\d{2})|(20(${validYears})))-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01])/`;

      form
        .field(node, {
          name: DOB_INPUT_NAME,
          type: "text",
          errorColor: "#a94442",
          placeholder: "YYYY-MM-DD",
          validations: ["required", datePat],
          css: {
            color: "#31708f",
            "font-size": "20px",
            "margin-y": "0px",
            height: "38px",
          },
        })
        .mask("9999-99-99");
    },
    [assignDebitCardsOpen, form]
  );

  const [getDebitCards, { loading: loadingOwner, data: ownerData }] =
    useLazyQuery(
      !groupId ? GET_ORGANIZATION_DEBIT_CARDS : GET_GROUP_DEBIT_CARDS,
      {
        variables: { spendGroupDebitCardsId: groupId },
        fetchPolicy: "network-only",
      }
    );

  const [getInvites, { loading, data }] = useLazyQuery(GET_DEBIT_CARD_INVITES, {
    variables: {
      groupId: groupId ?? null,
    },
    fetchPolicy: "network-only",
  });
  const [selectedOption, setSelectedOption] = useState("");
  const [users, setUsers] = useState<SnapSelectMenuOption[]>([]);
  const [diffAddressOpen, setDiffAddressOpen] = useState(false);
  const [debitCardInfo, setDebitCardInfo] = useState<SpendDebitCardInput>({
    dateOfBirth: "",
    shipping: {
      city: "",
      postalCode: "",
      state: "",
      street: "",
      street2: null,
    },
    userId: "",
  });
  const [hasErrors, setHasErrors] = useState<debitCardError>({
    cardLimit: false,
    cardAssignedTo: false,
    birthdate: false,
    shippingNotSelected: false,
    shippingAddress: {
      city: false,
      zip: false,
      state: false,
      address: false,
    },
  });
  const [btnState, setBtnState] = useState<BtnState>(BtnState.BASE);

  const handleDebitCardCreate = () => {
    if (form === undefined) {
      throw new Error("VGSCollect form not instantiated");
    }
    if (debitCardInfo.userId === "") {
      setHasErrors({ ...hasErrors, cardAssignedTo: true });
      hasErrors.cardAssignedTo = true;
    }
    if (selectedOption === "") {
      setHasErrors({ ...hasErrors, shippingNotSelected: true });
      hasErrors.shippingNotSelected = true;
    }
    if (
      diffAddressOpen &&
      (debitCardInfo.shipping?.city === "" ||
        debitCardInfo.shipping?.postalCode === "" ||
        debitCardInfo.shipping?.state === "" ||
        debitCardInfo.shipping?.street === "")
    ) {
      let { city, postalCode, state, street } = debitCardInfo.shipping;
      setHasErrors({
        ...hasErrors,
        shippingAddress: {
          city: city.trim() === "",
          zip: postalCode.trim() === "",
          state: state.trim() === "",
          address: street.trim() === "",
        },
      });
      hasErrors.shippingAddress = {
        city: city.trim() === "",
        zip: postalCode.trim() === "",
        state: state.trim() === "",
        address: street.trim() === "",
      };
    }
    let {
      cardAssignedTo,
      shippingNotSelected,
      shippingAddress: { city, zip, state, address },
    } = hasErrors;
    if (
      cardAssignedTo ||
      shippingNotSelected ||
      city ||
      zip ||
      state ||
      address
    ) {
      toast?.setToastProps({
        message: "Please fill in all the input fields",
        type: "danger",
      });
      toggleToast();
    } else {
      const foundCard = cards.find(
        (card) => card.userId === debitCardInfo.userId
      );
      if (foundCard) {
        setHasErrors({
          ...hasErrors,
          cardLimit: true,
        });
        toast?.setToastProps({
          message: `Only one card assigned per user per ${type} allowed`,
          type: "danger",
        });
        toggleToast();
      } else {
        setBtnState(BtnState.DISABLED);
        const query = !groupId
          ? CREATE_ORG_DEBIT_CARD_STRING
          : CREATE_GROUP_DEBIT_CARD_STRING;
        form.submit(
          "/graphql",
          {
            data: (formValues: Record<string, unknown>) => {
              const vgsInput = {
                ...debitCardInfo,
                dateOfBirth: formValues["dob-input"],
              };
              const variables = !groupId
                ? { vgsInput }
                : { vgsInput, id: groupId };
              return {
                query,
                variables,
              };
            },
            method: "POST",
            withCredentials: true,
          },
          async (responseStatus: any, data: any) => {
            try {
              setHasErrors({ ...hasErrors, birthdate: false });
              await getDebitCards();
              assignDebitCardsToggle();
              setForm(undefined);
            } catch (e) {}
          },
          (errors: any) => {
            setBtnState(BtnState.BASE);
            setHasErrors({ ...hasErrors, birthdate: true });
            toast?.setToastProps({
              message: "Birthdate has error",
              type: "danger",
            });
            toggleToast();
          }
        );
      }
    }
  };

  useEffect(() => {
    setSelectedOption("");
    setBtnState(BtnState.BASE);
    if (form !== undefined) {
      return;
    }
    if (process.env.REACT_APP_VGS_VAULT === undefined) {
      throw new Error("REACT_APP_VGS_VAULT not set");
    }
    if (process.env.REACT_APP_VGS_ENVIRONMENT === undefined) {
      throw new Error("REACT_APP_VGS_ENVIRONMENT not set");
    }

    const formInstance = window.VGSCollect.create(
      process.env.REACT_APP_VGS_VAULT,
      process.env.REACT_APP_VGS_ENVIRONMENT,
      () => {}
    );
    formInstance.useCname(process.env.REACT_APP_VGS_CNAME || "");
    setForm(formInstance);
  }, [form]);

  useEffect(() => {
    getInvites();
  }, [getInvites]);

  useEffect(() => {
    let orgOwnerData = {
      name: `${Program?.organization?.owner?.firstName} ${Program?.organization?.owner?.lastName}`,
      value: Program?.organization?.userId,
      selected: false,
    };

    if (!loading && data && data.spendDebitCardInvites) {
      const mappedInvites = data.spendDebitCardInvites.invites.map(
        (invite: SpendInvite) => {
          return {
            name: `${invite.firstName} ${invite.lastName}`,
            value: invite.userId,
            selected: false,
          };
        }
      );
      setUsers([orgOwnerData, ...mappedInvites]);
    }
    // eslint-disable-next-line
  }, [loading, data, groupId, ownerData, loadingOwner, orgInfo]);

  return (
    <CustomModal
      isOpen={assignDebitCardsOpen}
      toggle={assignDebitCardsToggle}
      title={"Order Card"}
      btn1={{
        text: "Order Card",
        btnStyle: "primary",
        onClick: handleDebitCardCreate,
        btnState: btnState,
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: assignDebitCardsToggle,
      }}
      customStyle="lg:mt-[10px] lg:h-[500px]"
    >
      <>
        {isToastOpen && (
          <ToastMessage
            message={toast.message}
            isToastOpen={isToastOpen}
            toggleToast={toggleToast}
            type={toast.type}
            className="lg:z-50 lg:mt-[50px] lg:w-[30%] lg:mx-10"
          />
        )}
        <div className="modal-card">
          <div className="flex flex-col-reverse lg:grid grid-cols-2">
            <div className="mt-4 lg:mt-0 lg:border-r lg:pr-4">
              <p className="text-base font-medium mb-4">Choose cardholder</p>
              {!loading ? (
                <SnapSelectMenu
                  label="Assign Card To"
                  helpText={`Cards can only be assigned to ${
                    !groupId ? "program" : "group"
                  } admin or group assistants.`}
                  selectMenuOptions={users}
                  placeholder="Select Cardholder"
                  onSnap-select-menu-updated={(e) => {
                    let selectedItem = e.detail.find((e) => e.selected);
                    debitCardInfo.userId = selectedItem?.value ?? "";
                    setHasErrors({
                      ...hasErrors,
                      cardAssignedTo: false,
                      cardLimit: false,
                    });
                  }}
                  error={hasErrors.cardAssignedTo || hasErrors.cardLimit}
                />
              ) : (
                <SpinnerContainer loading={loading} />
              )}
              <p className="mt-4">Assignee Birthdate</p>
              <div
                ref={dobRef}
                id="DOB_Input"
                className={`h-10 border-2 rounded-lg pl-3 border-gray-200 ${
                  hasErrors.birthdate && "border-red-300 border"
                }`}
              ></div>
              <p className="font-medium mt-4">Select shipped address</p>
              <div
                className={`flex border rounded-lg p-4 mt-4 ${
                  hasErrors.shippingNotSelected && "border-red-300"
                }`}
              >
                <SnapRadioButton
                  checked={selectedOption === "radio"}
                  onClick={() => {
                    setSelectedOption("radio");
                    setDiffAddressOpen(false);
                    if (debitCardInfo.shipping) {
                      debitCardInfo.shipping.street = orgInfo?.street || "";
                      debitCardInfo.shipping.city = orgInfo?.city || "";
                      debitCardInfo.shipping.state = orgInfo?.state || "";
                      debitCardInfo.shipping.postalCode = orgInfo?.zip || "";
                      debitCardInfo.shipping.street2 = orgInfo?.street2;
                    }
                    setHasErrors({
                      ...hasErrors,
                      shippingNotSelected: false,
                      shippingAddress: {
                        address: false,
                        city: false,
                        state: false,
                        zip: false,
                      },
                    });
                  }}
                />
                <div>
                  <p>{orgInfo?.nickname || orgInfo?.legalName}</p>
                  <p>{orgInfo?.street}</p>
                  {orgInfo?.street2 && <p>{orgInfo?.street2}</p>}
                  <p>{`${orgInfo?.city} ${orgInfo?.state}, ${orgInfo?.zip}`}</p>
                </div>
              </div>
              {!diffAddressOpen && (
                <p
                  className="text-sm font-semibold text-blue-600 cursor-pointer mt-6 lg:mt-4"
                  onClick={() => {
                    setSelectedOption("diffAddress");
                    setDiffAddressOpen(true);
                    setHasErrors({ ...hasErrors, shippingNotSelected: false });
                    setDebitCardInfo({
                      ...debitCardInfo,
                      shipping: {
                        city: "",
                        street: "",
                        state: "",
                        postalCode: "",
                        street2: null,
                      },
                    });
                  }}
                >
                  Use Different Address
                </p>
              )}
              {diffAddressOpen && (
                <div className="flex flex-col border rounded-lg p-4 mt-4">
                  <SnapInput
                    label="Address"
                    _id={"Address_Input"}
                    className="mt-4"
                    onSnap-input-change={(e) =>
                      (debitCardInfo.shipping!.street =
                        e.detail.target.value || "")
                    }
                    onBlur={(e) => {
                      debitCardInfo.shipping!.street = e.target.value || "";
                      setHasErrors({
                        ...hasErrors,
                        shippingAddress: {
                          ...hasErrors.shippingAddress,
                          address: false,
                        },
                      });
                    }}
                    error={hasErrors.shippingAddress.address}
                  />
                  <SnapInput
                    label="Address Line 2"
                    _id={"Address_Line2_Input"}
                    className="mt-4"
                    onSnap-input-change={(e) =>
                      (debitCardInfo.shipping!.street2 =
                        e.detail.target.value || null)
                    }
                    onBlur={(e) => {
                      debitCardInfo.shipping!.street2 = e.target.value || null;
                    }}
                  />
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <SnapInput
                      label="City"
                      _id={"City_Input"}
                      onSnap-input-change={(e) =>
                        (debitCardInfo.shipping!.city = e.detail.target.value)
                      }
                      onBlur={(e) => {
                        debitCardInfo.shipping!.city = e.target.value;
                        setHasErrors({
                          ...hasErrors,
                          shippingAddress: {
                            ...hasErrors.shippingAddress,
                            city: false,
                          },
                        });
                      }}
                      error={hasErrors.shippingAddress.city}
                    />
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <p>State</p>
                        <SnapSelectMenu
                          selectMenuOptions={DebitCardStates}
                          onSnap-select-menu-updated={(e) => {
                            const selectedItem = e.detail.find(
                              (state) => state.selected
                            );
                            if (selectedItem) {
                              debitCardInfo.shipping!.state =
                                selectedItem.value!;
                            } else {
                              debitCardInfo.shipping!.state = "";
                            }
                          }}
                        />
                      </div>
                      <SnapInput
                        _type="number"
                        label="Zip"
                        _id={"Zip_Input"}
                        onSnap-input-change={(e) =>
                          (debitCardInfo.shipping!.postalCode =
                            e.detail.target.value)
                        }
                        onBlur={(e) => {
                          debitCardInfo.shipping!.postalCode = e.target.value;
                          setHasErrors({
                            ...hasErrors,
                            shippingAddress: {
                              ...hasErrors.shippingAddress,
                              zip: false,
                            },
                          });
                        }}
                        error={hasErrors.shippingAddress.zip}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="border-b pb-4 lg:pl-6  lg:border-none lg:pb-0">
              <p className="text-base font-medium">About Debit Cards</p>
              <ul className="list-disc ml-5 mt-2">
                <li>Cards will arrive in 10-14 business days.</li>
                <li>
                  Cardholders must sign in to Snap! Spend to activate the card
                  before it can be used.
                </li>
                <li>
                  A group can have three debit cards. To change users, the group
                  admin can delete one card and then assign a new user to a new
                  card.
                </li>
              </ul>
              <p className="text-xs text-gray-500 mt-4">
                Daily limits for debit cards start at $2,000.00, including a
                $500.00 daily limit on ATM withdrawals.
              </p>
            </div>
          </div>
        </div>
      </>
    </CustomModal>
  );
}

export default AssignDebitCard;
