import { useMutation } from "@apollo/client";
import type { DropdownItemSelected } from "@snap-mobile/snap-ui/dist/types/utils";
import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import DisplayContext from "context/display-context";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import {
  SpendSortInput,
  useSpendInvitesFilteredQuery,
  useSpendSimpleGroupsQuery,
  useSpendUserInviteArchiveMutation,
  useSpendUserRoleUpdateIsApproverMutation,
} from "graphql/generated";
import { CREATE_USER_INVITE } from "graphql/mutations/invite";
import { UPDATE_USER_ROLE } from "graphql/mutations/role";
import { GET_INVITED_USERS } from "graphql/queries/invites";
import { HandleRoleNameChangeToFront } from "helpers/front-end-role-change";
import { getBadgeColor } from "helpers/status-color";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import Filter from "shared-components/filter";
import FloatingActionBtn from "shared-components/floating-action-btn";
import { SearchInput } from "shared-components/search-input";
import ShowingResults from "shared-components/showing-results";
import { SnapBadge, SnapButton, SnapPagination } from "suit";
import { LabelValueObject } from "types/label-value-object";
import { SpendPermissions } from "types/roles-permissions";
import { ITEMS_PER_PAGE } from "../../../constants";
import Cards from "./card";
import ChangeUserRoleModal from "./modals/change-user-role-modal";
import InviteUserModal from "./modals/invite-users-modal";
import RemoveUserModal from "./modals/remove-user-modal";
import Table from "./table";
import { SpendRosterResend } from "types/group-roster";
import BulkActions from "shared-components/bulk-actions";
import { useContextStrict } from "helpers/context-strict";
import SendEmail from "shared-components/modal/send-email";
import ErrorEmail from "shared-components/fixed-modal/error-email";
import {
  HandleAllCheckboxAction,
  hasAllById,
} from "helpers/all-checkbox-action";
import { InvitePlus } from "types/invite";
import { bulkActionsInviteMapper } from "helpers/bulk-actions-mapper";
import EnableApprover from "shared-components/fixed-modal/enable-approver";
import DisableApprover from "shared-components/fixed-modal/disable-approver";

export type GroupType = {
  id: string;
  name: string;
  selected: boolean;
}[];

const FILTER_INVITE_OPTIONS: DropdownMenuItem[] = [
  {
    name: "All Users",
    text: "All Users",
    value: "-all-",
    selected: true,
  },
  { name: "Active", text: "Active", value: "accepted", selected: false },
  {
    name: "Not Signed Up",
    text: "Not Signed Up",
    value: "pending",
    selected: false,
  },
  {
    name: "Undeliverable",
    text: "Undeliverable",
    value: "undeliverable",
    selected: false,
  },
];

function Program() {
  const Display = useContext(DisplayContext);
  const toast = useContextStrict(ToastContext);
  const user = useContext(UserContext);

  const { isOpen: changeRoleOpen, toggle: changeRoleToggle } = useModal();
  const { isOpen: removeUserOpen, toggle: removeUserToggle } = useModal();
  const { isOpen: inviteUserOpen, toggle: inviteUserToggle } = useModal();
  const { isOpen: sendEmailOpen, toggle: sendEmailToggle } = useModal();
  const { isOpen: errorMessageOpen, toggle: errorMessageToggle } = useModal();
  const { isOpen: enableApproverOpen, toggle: enableApproverToggle } =
    useModal();
  const { isOpen: disableApproverOpen, toggle: disableApproverToggle } =
    useModal();

  const [selectedSeasonRosterForResend, setSelectedSeasonRosterForResend] =
    useState<SpendRosterResend[]>([]);
  const [selectedItems, setSelectedItems] = useState<InvitePlus[]>([]);
  const [isNameBoxChecked, setNameBoxChecked] = useState(false);
  const [allGroups, setAllGroups] = useState<GroupType>([]);
  const [selectedStatus, setStatus] = useState<string>("");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState<SpendSortInput | undefined>();
  const [menuClickData, setMenuClickData] = useState<InvitePlus | null>(null);
  const [isBtnActive, setIsBtnActive] = useState(true);

  const [collectionData, setCollectionData] = useState<InvitePlus[]>([
    {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      type: "",
      status: "",
      isChecked: false,
      hasWarning: false,
      userId: "",
      isArchived: false,
    },
  ]);

  const canAddUsers =
    user?.checkSpendPermission(SpendPermissions.programAdminsUpdate) ?? false;
  const canEditUser =
    user?.checkSpendPermission(SpendPermissions.programAdminsUpdate) ?? false;

  const menuItems: DropdownMenuItem[] = canEditUser
    ? [
        {
          text: "Remove User",
          name: "Remove User",
          value: "removePermissions",
        },
        { text: "Change Role", name: "Change Role", value: "changeRole" },
      ]
    : [];

  useSpendSimpleGroupsQuery({
    onCompleted: (data) => {
      if (data && data.spendGroups?.groups) {
        const groupsArray = data.spendGroups.groups.map((gr) => ({
          ...gr,
          selected: false,
        }));
        if (groupsArray) setAllGroups(groupsArray as GroupType);
      }
    },
  });
  const reFetchUser = {
    query: GET_INVITED_USERS,
    variables: {
      filterBy: "program",
    },
  };
  const reFetchUserGroup = {
    query: GET_INVITED_USERS,
    variables: {
      filterBy: "group",
    },
    fetchPolicy: "network-only",
  };

  const [createNewUser, { loading: loadingNewUsers, data: newUserData }] =
    useMutation(CREATE_USER_INVITE, {
      refetchQueries: [reFetchUser, reFetchUserGroup],
    });

  const [
    changeRole,
    {
      loading: loadingRoleChange,
      data: roleChangeData,
      error: changeRoleError,
    },
  ] = useMutation(UPDATE_USER_ROLE, {
    refetchQueries: [reFetchUser, reFetchUserGroup],
  });

  const [
    updateApproverSetting,
    {
      loading: loadingApproverUpdate,
      data: approverUpdateSettingData,
      error: approverUpdateError,
    },
  ] = useSpendUserRoleUpdateIsApproverMutation({
    refetchQueries: ["SpendInvitesFiltered"],
  });

  const [archiveUser, { loading: loadingArchiveUser, data: archiveUserData }] =
    useSpendUserInviteArchiveMutation();

  const { loading: invitesLoading, data } = useSpendInvitesFilteredQuery({
    variables: {
      where: {
        typeContext: "program",
        status: selectedStatus || undefined,
        nameIncludes:
          searchText && searchText.length > 2 ? searchText : undefined,
      },
      pagination: {
        limit: ITEMS_PER_PAGE,
        offset: ITEMS_PER_PAGE * page,
      },
      sort: sort,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!invitesLoading && data && data.spendInvitesFiltered) {
      const inviteIds = new Set(selectedItems.map(({ id }) => id));

      const programUsersArray = data.spendInvitesFiltered.invites?.map(
        (invite) => {
          let isChecked = false;
          if (invite?.id) {
            isChecked = inviteIds.has(invite.id);
          }
          return { ...invite, isChecked, hasWarning: false };
        }
      );
      if (programUsersArray)
        setCollectionData(programUsersArray as InvitePlus[]);
      setNameBoxChecked(
        programUsersArray?.every((user) => user.isChecked) ?? false
      );
      setTotal(data.spendInvitesFiltered.count ?? 0);
    }
    // eslint-disable-next-line
  }, [invitesLoading, data]);

  useEffect(() => {
    if (!loadingApproverUpdate && approverUpdateSettingData) {
      toast?.setToast({
        message: "Successfully updated approver setting",
        type: "success",
      });
      setIsBtnActive(true);
      if (enableApproverOpen) {
        enableApproverToggle();
      }
      if (disableApproverOpen) {
        disableApproverToggle();
      }
    }

    if (!loadingApproverUpdate && approverUpdateError) {
      toast?.setToast({
        message: "Error updating approver setting",
        type: "danger",
      });
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingApproverUpdate, approverUpdateSettingData, approverUpdateError]);

  useEffect(() => {
    if (!loadingRoleChange && roleChangeData) {
      toast?.setToast({
        message: "Role Change Successfully",
        type: "success",
      });
      setIsBtnActive(true);
      changeRoleToggle();
    }

    if (!loadingRoleChange && changeRoleError) {
      toast?.setToast({
        message: "Error changing users role",
        type: "danger",
      });
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingRoleChange, roleChangeData]);

  useEffect(() => {
    if (!loadingNewUsers && newUserData) {
      toast?.setToast({
        message: "Invite(s) has been sent",
        type: "success",
      });
      setIsBtnActive(true);
      inviteUserToggle();
    }
    // eslint-disable-next-line
  }, [loadingRoleChange, roleChangeData]);

  useEffect(() => {
    if (!loadingArchiveUser && archiveUserData) {
      toast?.setToast({
        message: "User Successfully Archived",
        type: "success",
      });
      setIsBtnActive(true);
      removeUserToggle();
    }
    // eslint-disable-next-line
  }, [loadingArchiveUser, archiveUserData]);

  useEffect(() => {
    const itemsResend = bulkActionsInviteMapper(selectedItems);
    setSelectedSeasonRosterForResend(itemsResend);
  }, [selectedItems]);

  const saveSendInvitations = (staff: InvitePlus[]) => {
    staff.forEach((user: InvitePlus) => {
      createNewUser({
        variables: {
          input: {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email?.toLowerCase(),
            type: user.type,
            groupId: user.groupId,
          },
        },
        refetchQueries: ["SpendInvitesFiltered"],
        fetchPolicy: "network-only",
      });
    });
  };

  const handleRoleChange = (
    user: string,
    role: string,
    group: string | null
  ) => {
    if (
      page > 0 &&
      collectionData.length === 1 &&
      (role === "group_staff" || role === "group_observer")
    ) {
      setPage(page - 1);
    }

    changeRole({
      variables: {
        roleName: role,
        userId: user,
        groupId: group,
      },
      refetchQueries: ["SpendInvitesFiltered"],
      fetchPolicy: "network-only",
    });
  };

  const handleApproverSettingUpdate = (
    userId: string,
    type: string,
    isEnabled: boolean
  ) => {
    updateApproverSetting({
      variables: {
        input: {
          userId,
          type,
          isEnabled,
        },
      },
    });
  };

  const prepLeftData = (collection: InvitePlus) => {
    const leftData: LabelValueObject[] = [];
    leftData.push({
      key: "Email",
      value: collection?.email || "",
      className: "break-all",
    });
    leftData.push({
      key: "Role",
      value: HandleRoleNameChangeToFront(collection.type || "") ?? "",
      className: "capitalize",
    });
    return leftData;
  };

  const getBadge = (label: string) => {
    let badgeLabel = "";
    switch (label) {
      case "accepted":
        badgeLabel = "Active";
        break;
      default:
        badgeLabel = label;
        break;
    }

    return (
      <SnapBadge
        className="text-sm ml-2 capitalize whitespace-nowrap"
        title="Test"
        size="sm"
        color={getBadgeColor(badgeLabel)}
      >
        {badgeLabel}
      </SnapBadge>
    );
  };

  const handleSelectInviteStatus = (e: CustomEvent<DropdownItemSelected>) => {
    const status = e.detail.value;
    setStatus(status === "-all-" ? "" : status);
  };

  const handleCheckboxAction = (idx: number, data: InvitePlus) => {
    const selectedItem: InvitePlus = {
      ...data,
      isChecked: !data.isChecked,
    };

    let currentlySelectedItems = [...selectedItems];
    if (selectedItem.isChecked) {
      currentlySelectedItems.push(selectedItem);
    } else {
      let idx = currentlySelectedItems.findIndex(
        (i) => i.id === selectedItem.id
      );
      currentlySelectedItems.splice(idx, 1);
    }
    setSelectedItems(currentlySelectedItems);

    collectionData.splice(idx, 1, selectedItem);
    setCollectionData(collectionData);
    setNameBoxChecked(hasAllById(currentlySelectedItems, collectionData));
  };

  const handleDeselectAll = () => {
    setCollectionData(
      collectionData.map((invite) => {
        return {
          ...invite,
          isChecked: false,
        };
      })
    );
    setSelectedItems([]);
    setNameBoxChecked(false);
  };

  const menuAction = (value: string, cd: InvitePlus) => {
    setMenuClickData(cd);
    if (value === "removePermissions") {
      removeUserToggle();
    } else if (value === "changeRole") {
      changeRoleToggle();
    } else if (value === "disableApprover") {
      disableApproverToggle();
    } else if (value === "enableApprover") {
      enableApproverToggle();
    }
  };

  return (
    <div className="wrapper">
      <div className="card">
        <SearchInput setSearchValue={setSearchText} />
        <div className="lg:flex flex-col-reverse">
          <div>
            <div className="flex justify-between mt-4">
              <div className="text-lg font-semibold mb-2">
                Program Users Overview
              </div>
              {canAddUsers && (
                <SnapButton
                  variant="primary"
                  className="lg:flex hidden"
                  onClick={inviteUserToggle}
                  icon="user-add-solid"
                  size="sm"
                >
                  Add Users
                </SnapButton>
              )}
            </div>
            <ShowingResults
              totalNumOfResults={total}
              numOfResultsBeingDisplayed={
                total <= 10
                  ? total
                  : ITEMS_PER_PAGE * page + 10 >= total
                  ? total
                  : ITEMS_PER_PAGE * page + 10
              }
              startingNumOfResults={total === 0 ? 0 : ITEMS_PER_PAGE * page + 1}
              hasCheckbox
              checkboxAction={(e) => {
                setNameBoxChecked(e.target.checked);
                HandleAllCheckboxAction(
                  isNameBoxChecked,
                  collectionData,
                  setCollectionData,
                  setSelectedItems,
                  selectedItems
                );
              }}
              isNameBoxChecked={isNameBoxChecked}
              hideCheckboxOnWeb
            />
            <Divider isVisibleOnMobile className="lg:hidden mt-2 mb-5" />
          </div>
          <div>
            <Filter
              dropdownOptions={FILTER_INVITE_OPTIONS}
              handleSelect={handleSelectInviteStatus}
              className="lg:mt-4 mb-4"
            />
            <Divider />
          </div>
        </div>
        <div className="relative">
          {Display?.isDesktop ? (
            <Table
              collectionData={collectionData}
              setCollectionData={setCollectionData}
              getBadge={getBadge}
              menuItems={menuItems}
              sort={sort}
              setSort={setSort}
              loading={invitesLoading}
              canAddUsers={canAddUsers}
              isNameBoxChecked={isNameBoxChecked}
              setNameBoxChecked={setNameBoxChecked}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              handleCheckboxAction={handleCheckboxAction}
              menuAction={menuAction}
            />
          ) : (
            <Cards
              collectionData={collectionData}
              loading={invitesLoading}
              getBadge={getBadge}
              prepLeftData={prepLeftData}
              menuItems={menuItems}
              handleCheckboxAction={handleCheckboxAction}
              menuAction={menuAction}
            />
          )}
        </div>

        <ChangeUserRoleModal
          isOpen={changeRoleOpen}
          toggle={changeRoleToggle}
          data={menuClickData!}
          handleRoleChange={handleRoleChange}
          allGroups={allGroups}
          isBtnActive={isBtnActive}
          setIsBtnActive={setIsBtnActive}
        />
        <RemoveUserModal
          isOpen={removeUserOpen}
          toggle={removeUserToggle}
          data={menuClickData!}
          isBtnActive={isBtnActive}
          setIsBtnActive={setIsBtnActive}
          handleRemove={(id) => {
            if (page > 0 && collectionData.length === 1) {
              setPage(page - 1);
            }
            archiveUser({
              variables: {
                spendUserInviteArchiveId: id,
              },
              refetchQueries: ["SpendInvitesFiltered"],
            });
          }}
        />
        <InviteUserModal
          isOpen={inviteUserOpen}
          toggle={inviteUserToggle}
          handleSendInvites={saveSendInvitations}
          allGroups={allGroups}
          usersData={collectionData}
          isBtnActive={isBtnActive}
          setIsBtnActive={setIsBtnActive}
        />
        <SnapPagination
          currentPage={page}
          itemCount={total}
          pageSize={ITEMS_PER_PAGE}
          onSnap-pagination-page-changed={(e) => {
            setPage(e.detail);
          }}
        />
        <SendEmail
          sendEmailOpen={sendEmailOpen}
          sendEmailToggle={sendEmailToggle}
          selectedItems={selectedItems}
          role={"Staff"}
        />
        {enableApproverOpen && (
          <EnableApprover
            isEnableApproverOpen={enableApproverOpen}
            enableApproverToggle={enableApproverToggle}
            data={menuClickData!}
            handleApproverSettingUpdate={handleApproverSettingUpdate}
          />
        )}
        {disableApproverOpen && (
          <DisableApprover
            disableApproverOpen={disableApproverOpen}
            disableApproverToggle={disableApproverToggle}
            data={menuClickData!}
            handleApproverSettingUpdate={handleApproverSettingUpdate}
          />
        )}
        <ErrorEmail isOpen={errorMessageOpen} toggle={errorMessageToggle} />
        {selectedItems.length > 0 && (
          <BulkActions
            numOfItemsSelected={selectedItems.length}
            deselectAllAction={handleDeselectAll}
            sendEmailToggle={sendEmailToggle}
            selectedItems={selectedSeasonRosterForResend}
            errorMessageToggle={errorMessageToggle}
            toast={toast}
          />
        )}
      </div>
      {canAddUsers && (
        <FloatingActionBtn onClick={inviteUserToggle} icon={"plus-solid"} />
      )}
    </div>
  );
}

export default Program;
