import {
  PaymentScheduleStatus,
  SpendGroupRosterStatusEnum,
} from "../graphql/generated";
import { toTitleCase } from "./string";

export const inviteStatusToText = (status: string, defaultText = "") => {
  switch (status) {
    case "accepted":
      return "Active";
    case "pending":
      return "Not Signed Up";
    case "expired":
      return "Expired";
    case "archived":
      return "Archived";
    default:
      return "";
  }
};

export const cardStatusToText = (status: string, defaultText = "") => {
  switch (status) {
    case "Active":
      return "Activated";
    case "Inactive":
      return "Inactive";
    default:
      return status || defaultText;
  }
};
export const isPsPublished = (status?: string | null) => {
  return status === PaymentScheduleStatus.Published.toString();
};
export const isPsDraft = (status?: string | null) => {
  return status === PaymentScheduleStatus.Draft;
};
export const isPsValid = (status?: string | null) => {
  return (
    status === PaymentScheduleStatus.Published ||
    status === PaymentScheduleStatus.OnHold ||
    status === PaymentScheduleStatus.Draft
  );
};
export const getPsLabel = (status?: string | null) => {
  switch (status) {
    case PaymentScheduleStatus.Draft:
      return "draft";
    case PaymentScheduleStatus.Published:
      return "published";
    case PaymentScheduleStatus.OnHold:
      return "on hold";
    default:
      return "n/a";
  }
};

export const rosterStatusToText = (
  status: SpendGroupRosterStatusEnum | string
) => {
  const MAPPER = {
    [SpendGroupRosterStatusEnum.Paid]: "Paid",
    [SpendGroupRosterStatusEnum.PastDue]: "Past Due",
    [SpendGroupRosterStatusEnum.Archived]: "Archived",
    [SpendGroupRosterStatusEnum.Canceled]: "Canceled",
    [SpendGroupRosterStatusEnum.Expired]: "Expired",
    [SpendGroupRosterStatusEnum.NotSignedUp]: "Not Signed Up",
    [SpendGroupRosterStatusEnum.NoInviteSent]: "No Invite Sent",
    [SpendGroupRosterStatusEnum.AutopayStop]: "Autopay Stopped",
    [SpendGroupRosterStatusEnum.AwaitingApproval]: "Awaiting Approval",
  };

  return (
    MAPPER[status as SpendGroupRosterStatusEnum] ??
    toTitleCase(status.replaceAll("_", " "))
  );
};
