import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import React, { useEffect, useState } from "react";
import { SnapCheckboxButton, SnapDropDown, SnapTable } from "suit";
import { HandleRoleNameChangeToFront } from "helpers/front-end-role-change";
import { SpendSortInput, SpendSortOrderEnum } from "../../../graphql/generated";
import SortIcon from "../../../shared-components/table/sort-icon";
import { SpinnerContainer } from "shared-components/spinner";
import NoResults from "shared-components/table/no-results";
import { HandleAllCheckboxAction } from "helpers/all-checkbox-action";
import { InvitePlus } from "types/invite";
import { useContextStrict } from "helpers/context-strict";
import SplitIOContext from "context/splitio-context";

type TableProps = {
  collectionData: InvitePlus[];
  setCollectionData: (userArray: any) => void;
  getBadge: (status: string) => JSX.Element;
  menuItems: DropdownMenuItem[];
  sort: SpendSortInput | undefined;
  setSort: React.Dispatch<React.SetStateAction<SpendSortInput | undefined>>;
  loading: boolean;
  canAddUsers: boolean;
  isNameBoxChecked: boolean;
  setNameBoxChecked: (value: boolean) => void;
  setSelectedItems: React.Dispatch<React.SetStateAction<InvitePlus[]>>;
  selectedItems: InvitePlus[];
  handleCheckboxAction: (idx: number, data: InvitePlus) => void;
  menuAction: (value: string, cd: InvitePlus) => void;
};

function Table({
  collectionData,
  setCollectionData,
  getBadge,
  menuItems,
  sort,
  setSort,
  loading,
  canAddUsers,
  isNameBoxChecked,
  setNameBoxChecked,
  setSelectedItems,
  selectedItems,
  handleCheckboxAction,
  menuAction,
}: TableProps) {
  const splits = useContextStrict(SplitIOContext);
  const [spe1288DualApproval, setSPE1288DualApproval] = useState(false);
  useEffect(() => {
    setSPE1288DualApproval(splits.isTreatmentOn(splits.act.spe1288));
  }, [splits]);

  const toggleSort = (sortField: string) => {
    setSort({
      field: sortField,
      order:
        sortField !== sort?.field
          ? SpendSortOrderEnum.Asc
          : sort?.order === SpendSortOrderEnum.Asc
          ? SpendSortOrderEnum.Desc
          : SpendSortOrderEnum.Asc,
    });
  };

  return (
    <SnapTable>
      <table className="ui celled hidden lg:table">
        <thead>
          <tr>
            {canAddUsers && (
              <th className="w-2">
                <SnapCheckboxButton
                  checked={isNameBoxChecked}
                  onClick={(e) => {
                    setNameBoxChecked(e.currentTarget.checked);
                    HandleAllCheckboxAction(
                      isNameBoxChecked,
                      collectionData,
                      setCollectionData,
                      setSelectedItems,
                      selectedItems
                    );
                  }}
                />
              </th>
            )}
            <th className="w-[15%]">
              Name
              <SortIcon
                field={"name"}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            </th>
            <th>
              Email
              <SortIcon
                field={"email"}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            </th>
            <th>
              Role
              <SortIcon
                field={"type"}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            </th>
            <th>
              Status
              <SortIcon
                field={"status"}
                toggleSort={toggleSort}
                currentSort={sort}
              />
            </th>
            <th>{/*Action*/}</th>
          </tr>
        </thead>
        <tbody>
          <SpinnerContainer loading={loading} inTable={true} colSpan={5} />
          {!loading &&
            collectionData.map((cd, idx) => {
              const approverMenuItem: DropdownMenuItem = {
                name: `${cd.isApprover ? "Disable" : "Enable"} Approver`,
                text: `${cd.isApprover ? "Disable" : "Enable"} Approver`,
                value: `${cd.isApprover ? "disable" : "enable"}Approver`,
              };

              const newMenuItems = [...menuItems, approverMenuItem];

              return (
                <tr key={cd.id || idx}>
                  {canAddUsers && (
                    <td className="checkbox-cell">
                      <SnapCheckboxButton
                        checked={cd.isChecked}
                        onClick={() => handleCheckboxAction(idx, cd)}
                      />
                    </td>
                  )}
                  <td
                    className={`${cd.isArchived && "opacity-50"}`}
                  >{`${cd.firstName} ${cd.lastName}`}</td>
                  <td className={`${cd.isArchived && "opacity-50"}`}>
                    {cd.email}
                  </td>
                  <td className={`capitalize ${cd.isArchived && "opacity-50"}`}>
                    {HandleRoleNameChangeToFront(cd?.type || "")}
                  </td>
                  <td className={`py-6 ${cd.isArchived && "opacity-50"}`}>
                    {getBadge(cd.status || "")}
                  </td>
                  <td>
                    {(spe1288DualApproval
                      ? newMenuItems.length
                      : menuItems.length) > 0 && (
                      <SnapDropDown
                        options={spe1288DualApproval ? newMenuItems : menuItems}
                        minimal
                        onSnap-dropdown-item-selected={(e) =>
                          menuAction(e.detail.value, cd)
                        }
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          {collectionData.length === 0 && (
            <NoResults loading={!loading} inTable={true} colSpan={8} />
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
