import { gql } from "@apollo/client";

export const APPROVE_ORGANIZATION = gql`
  mutation SpendOrganizationApprove($spendOrganizationApproveId: String!) {
    spendOrganizationApprove(id: $spendOrganizationApproveId) {
      status
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation SpendOrganizationCreate($input: SpendOrganizationInput!) {
    spendOrganizationCreate(input: $input) {
      id
    }
  }
`;

export const GET_ORGANIZATION_UNIT_LINK = gql`
  query SpendOrganizationUnitLink {
    spendOrganizationUnitLink {
      attributes {
        url
      }
    }
  }
`;

export const CREATE_ORG_DEBIT_CARD = gql`
  mutation SpendOrganizationDebitCardCreate($input: SpendDebitCardInput!) {
    spendOrganizationDebitCardCreate(input: $input) {
      id
    }
  }
`;

export const ORG_ACCOUNT_TRANSFER = gql`
  mutation SpendOrganizationAccountTransfer(
    $input: SpendAccountTransferInput!
  ) {
    spendOrganizationAccountTransfer(input: $input) {
      id
      status
      reason
      approvalId
    }
  }
`;

export const ORG_EXTERNAL_TRANSFER = gql`
  mutation SpendOrganizationExternalTransfer(
    $input: SpendOrganizationExternalTransferInput!
  ) {
    spendOrganizationExternalTransfer(input: $input) {
      amount
      descriptor
      id
      status
    }
  }
`;
export const UPDATE_ORG_SETTINGS = gql`
  mutation SpendSettingsUpdate($input: SpendSettingsInput) {
    spendSettingsUpdate(input: $input) {
      id
    }
  }
`;

export const UPDATE_ALL_GROUPS_SETTINGS = gql`
  mutation SpendSettingsUpdateAll($input: SpendSettingsInput) {
    spendSettingsUpdateAll(input: $input) {
      success
    }
  }
`;

export const ORG_SIGNUP_AGREEMENT_CREATE = gql`
  mutation SpendSignupAgreementCreate($input: SpendSignupAgreementInput!) {
    spendSignupAgreementCreate(input: $input) {
      id
    }
  }
`;

export const PATCH_ORG_CHECK_DEPOSIT = gql`
  mutation SpendOrganizationCheckDepositPatch(
    $input: SpendOrganizationCheckDepositTagsInput!
  ) {
    spendOrganizationCheckDepositPatch(input: $input) {
      success
    }
  }
`;

export const ADD_ORG_PAYEE = gql`
  mutation SpendOrganizationPayeeCreate($input: SpendPayeeInput!) {
    spendOrganizationPayeeCreate(input: $input) {
      payeeId
    }
  }
`;

export const SEND_ORG_CHECK = gql`
  mutation SpendOrganizationCheckSend($input: SpendCheckInput!) {
    spendOrganizationCheckSend(input: $input) {
      id
      type
      approvalId
      status
    }
  }
`;

export const CANCEL_ORG_CHECK = gql`
  mutation SpendOrganizationCheckCancel($checkId: String!) {
    spendOrganizationCheckCancel(checkId: $checkId) {
      id
    }
  }
`;

export const UPDATE_ORG_PAYEE = gql`
  mutation SpendOrganizationPayeeUpdate($input: SpendPayeeUpdateInput!) {
    spendOrganizationPayeeUpdate(input: $input) {
      payeeId
    }
  }
`;

export const DELETE_ORG_COUNTERYPARTY = gql`
  mutation SpendOrganizationCounterpartyDelete($counterpartyId: String!) {
    spendOrganizationCounterpartyDelete(counterpartyId: $counterpartyId) {
      success
    }
  }
`;

export const ORG_ACH_CREDIT = gql`
  mutation SpendOrganizationACHCredit($input: SpendOrgACHCredit) {
    spendOrganizationACHCredit(input: $input) {
      paymentId
      approvalId
    }
  }
`;

export const UPLOAD_APPLICATION_FILES = gql`
  mutation SpendUnitFileUpload($name: String!, $fileToUpload: String!) {
    spendUnitFileUpload(name: $name, fileToUpload: $fileToUpload) {
      id
    }
  }
`;
