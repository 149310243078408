import { useMutation, useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import ToastContext from "context/toast-context";
import { SpendPayees } from "graphql/generated";
import {
  ADD_GROUP_PAYEE,
  SEND_GROUP_CHECK,
  UPDATE_GROUP_PAYEE,
} from "graphql/mutations/group";
import {
  ADD_ORG_PAYEE,
  SEND_ORG_CHECK,
  UPDATE_ORG_PAYEE,
} from "graphql/mutations/organization";
import { GET_GROUP_PAYEES } from "graphql/queries/group";
import { GET_ORG_PAYEES } from "graphql/queries/organization";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { payeeErrors, sendCheckErrors } from "types/errors";
import AddPayeeContent from "./add-payee-modal-content";
import MakePayment from "./make-payment";
import ManagePayees from "./manage-payees";

type SendCheckProps = {
  isSendCheckOpen: boolean;
  sendCheckToggle: () => void;
  sendMoneyToggle: () => void;
  type: "Program" | "Group";
};

export type payeeData = {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
};

function SendCheck({
  isSendCheckOpen,
  sendCheckToggle,
  sendMoneyToggle,
  type,
}: SendCheckProps) {
  const toast = useContext(ToastContext);
  const Program = useContext(ProgramContext);
  const Group = useContext(GroupContext);
  const { isOpen: tooltipOpen, toggle: tooltipToggle } = useModal();
  const [isAddPayeeOpen, setIsPayeeOpen] = useState(false);
  const [payees, setPayees] = useState<SpendPayees[]>([]);
  const [payeeOptions, setPayeeOptions] = useState<SnapSelectMenuOption[]>([]);
  const [payeeData, setPayeeData] = useState<payeeData>({
    id: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const [payeeErrors, setPayeeErrors] = useState<payeeErrors>({
    nameError: false,
    address1Error: false,
    cityError: false,
    stateError: false,
    zipCodeError: false,
  });
  const [sendCheckErrors, setSendCheckError] = useState<sendCheckErrors>({
    payeeError: false,
    amountError: false,
    memoError: false,
  });

  const [amount, setAmount] = useState("");
  const [dailyLimit, setDailyLimit] = useState(0);
  const [memo, setMemo] = useState("");
  const [note, setNote] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(true);

  const [
    addPayee,
    { loading: loadingAddPayee, data: addPayeeData, error: addPayError },
  ] = useMutation(type === "Program" ? ADD_ORG_PAYEE : ADD_GROUP_PAYEE);
  const [updatePayee, { loading: loadingUpdatePayee, data: updatePayeeData }] =
    useMutation(type === "Program" ? UPDATE_ORG_PAYEE : UPDATE_GROUP_PAYEE);

  const {
    loading: getPayeeLoading,
    data: getPayeeData,
    refetch: refetchPayees,
  } = useQuery(type === "Program" ? GET_ORG_PAYEES : GET_GROUP_PAYEES, {
    variables:
      type === "Program"
        ? { orgId: Program?.organization?.id ?? "" }
        : { groupId: Group?.activeGroup?.id },
  });

  const [
    sendCheck,
    { loading: loadingCheck, data: checkData, error: sendCheckError },
  ] = useMutation(type === "Program" ? SEND_ORG_CHECK : SEND_GROUP_CHECK);

  useEffect(() => {
    if (!loadingCheck && sendCheckError) {
      setIsBtnActive(true);
      toast?.setToast({
        message: "Error: Unable to send check",
        type: "danger",
      });
    }

    if (!loadingCheck && checkData) {
      if (
        checkData.spendGroupCheckSend?.id ||
        checkData.spendOrganizationCheckSend?.id
      ) {
        toast?.setToast({
          message: "Check successfuly sent",
          type: "success",
        });
      }
      if (
        checkData.spendGroupCheckSend?.approvalId ||
        checkData.spendOrganizationCheckSend?.approvalId
      ) {
        toast?.setToast({
          message: "Check was submitted for approval",
          type: "success",
        });
      }
      handleResetError();
      handleResetValues();
      sendCheckToggle();
      sendMoneyToggle();
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingCheck, checkData]);

  useEffect(() => {
    if (!loadingAddPayee && addPayError) {
      toast?.setToast({
        message: "Error adding Payee",
        type: "danger",
      });
    }

    if (
      !loadingAddPayee &&
      addPayeeData &&
      (addPayeeData.spendOrganizationPayeeCreate ||
        addPayeeData.spendGroupPayeeCreate)
    ) {
      toast?.setToast({
        message: "Payee Added Successfully",
        type: "success",
      });
      refetchPayees();
      setIsPayeeOpen(false);
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingAddPayee, addPayeeData]);

  useEffect(() => {
    if (
      !loadingUpdatePayee &&
      updatePayeeData &&
      (updatePayeeData.spendOrganizationPayeeUpdate ||
        updatePayeeData.spendGroupPayeeUpdate)
    ) {
      refetchPayees();
      setIsPayeeOpen(false);
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingUpdatePayee, updatePayeeData]);

  useEffect(() => {
    if (
      !getPayeeLoading &&
      getPayeeData &&
      (getPayeeData.spendOrganizationPayees || getPayeeData.spendGroupPayees)
    ) {
      let payees: SpendPayees[] = [];
      if (!!getPayeeData.spendOrganizationPayees) {
        payees = getPayeeData.spendOrganizationPayees.payees;
      } else {
        payees = getPayeeData.spendGroupPayees.payees;
      }
      setPayees(payees);
      let options = payees.map((p) => {
        return {
          name: p.name,
          value: p.id,
          selected: false,
        };
      });
      setPayeeOptions(options);
    }
  }, [getPayeeData, getPayeeLoading]);

  useEffect(() => {
    if (type === "Program") {
      setDailyLimit(
        Program?.accountLimits?.checkPayment?.limits?.dailySent ?? 0
      );
    } else if (Group?.accountLimits) {
      setDailyLimit(Group?.accountLimits?.checkPayment?.limits?.dailySent ?? 0);
    } else {
      setDailyLimit(0);
    }
  }, [type, Program, Group?.accountLimits]);

  const handlePayeeData = () => {
    let zipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;
    let { name, address1, city, state, zipCode } = payeeData;
    let nameError = name === "";
    let address1Error = address1 === "";
    let cityError = city === "";
    let stateError = state === "";
    let zipCodeError = zipCode === "";
    if (nameError || address1Error || cityError || stateError || zipCodeError) {
      setPayeeErrors({
        nameError,
        address1Error,
        cityError,
        stateError,
        zipCodeError,
      });
      toast?.setToast({
        message: "Please fill in all the input fields",
        type: "danger",
      });
    } else if (!zipRegex.test(zipCode)) {
      toast?.setToast({
        message: "Zipcode is invalid, please correct.",
        type: "danger",
      });
      setPayeeErrors({
        ...payeeErrors,
        zipCodeError: true,
      });
    } else if (payees.length === 30) {
      toast?.setToast({
        message: "Payee limit is reached.",
        type: "danger",
      });
    } else {
      if (isEdit) {
        updatePayee({
          variables: {
            input: {
              ...payeeData,
              state,
              referenceId:
                type === "Program"
                  ? Program?.organization?.id ?? ""
                  : Group?.activeGroup?.id,
            },
          },
        });
      } else {
        const { id, ...payee } = payeeData;
        addPayee({
          variables: {
            input: {
              ...payee,
              state,
              referenceId:
                type === "Program"
                  ? Program?.organization?.id ?? ""
                  : Group?.activeGroup?.id,
            },
          },
        });
      }
      setIsBtnActive(false);
    }
  };

  const handleSendCheck = () => {
    let selectedPayee = payeeOptions.find((p) => p.selected);
    let payeeError = !selectedPayee;
    let amountError = amount === "" || amount === "0.00";
    let memoError = memo === "";
    if (payeeError || amountError || memoError) {
      setSendCheckError({
        payeeError,
        amountError,
        memoError,
      });
      toast?.setToast({
        message: "Please fill in all the input fields",
        type: "danger",
      });
    } else {
      if (Number(amount) * 100 > dailyLimit) {
        toast?.setToast({
          message: "Amount exceeds current available balance",
          type: "danger",
        });
        setSendCheckError({
          payeeError,
          amountError: true,
          memoError,
        });
      } else {
        let payE = payees.find((p) => p.id === selectedPayee?.value);
        if (payE === undefined) {
          toast?.setToast({
            message: "Selected Payee not found",
            type: "danger",
          });
        } else {
          let input = {
            payeeId: payE?.id,
            referenceId:
              type === "Program"
                ? Program?.organization?.id
                : Group?.activeGroup?.id,
            name: payE?.name,
            address: {
              street: payE?.address1,
              street2: payE?.address2,
              city: payE?.city,
              state: payE?.state,
              zip: payE?.zipCode,
            },
            amount: Math.round(Number(amount) * 100),
            description: memo,
            note: note,
          };
          sendCheck({
            variables: {
              input,
            },
          });
          setIsBtnActive(false);
        }
      }
    }
  };

  const handleResetError = () => {
    setSendCheckError({
      payeeError: false,
      amountError: false,
      memoError: false,
    });
  };

  const handleResetValues = () => {
    setPayeeOptions(
      payees.map((p) => {
        return {
          name: p.name,
          value: p.id,
          selected: false,
        };
      })
    );
    setAmount("");
    setNote("");
    setMemo("");
  };

  let btn1: BtnType = isAddPayeeOpen
    ? {
        text: "Continue",
        btnStyle: "primary",
        onClick: handlePayeeData,
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
      }
    : {
        text: "Make Payment",
        btnStyle: "primary",
        onClick: handleSendCheck,
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
      };
  let btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: () => {
      if (isAddPayeeOpen) {
        setIsPayeeOpen(false);
        setIsEdit(false);
        setPayeeData({
          id: "",
          name: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zipCode: "",
        });
      } else {
        sendCheckToggle();
        handleResetError();
        handleResetValues();
      }
    },
  };
  return (
    <CustomModal
      isOpen={isSendCheckOpen}
      toggle={() => {
        sendCheckToggle();
        setIsPayeeOpen(false);
        handleResetError();
        handleResetValues();
      }}
      title={isAddPayeeOpen ? "" : "Send Check"}
      btn1={selectedTab === 0 || isAddPayeeOpen ? btn1 : undefined}
      btn2={selectedTab === 0 || isAddPayeeOpen ? btn2 : undefined}
      hasBackBtn={
        isAddPayeeOpen
          ? {
              text: "back",
              onClick: () => {
                setIsPayeeOpen(false);
                setIsEdit(false);
                setPayeeData({
                  id: "",
                  name: "",
                  address1: "",
                  address2: "",
                  city: "",
                  state: "",
                  zipCode: "",
                });
              },
            }
          : undefined
      }
      customStyle={`lg:w-[1082px] lg:mt-10 lg:h-[650px]`}
      tabOptions={
        !isAddPayeeOpen
          ? {
              currentSelectedValue: selectedTab,
              tabs: [
                {
                  text: "Make Payment",
                  value: 0,
                  onClick: () => setSelectedTab(0),
                },
                {
                  text: "Manage Payees",
                  value: 1,
                  onClick: () => setSelectedTab(1),
                },
              ],
            }
          : undefined
      }
    >
      {!isAddPayeeOpen ? (
        {
          0: (
            <MakePayment
              dailyLimit={dailyLimit}
              tooltipToggle={tooltipToggle}
              tooltipOpen={tooltipOpen}
              payeeOptions={payeeOptions}
              setPayeeOptions={setPayeeOptions}
              setSendCheckError={setSendCheckError}
              sendCheckErrors={sendCheckErrors}
              amount={amount}
              setAmount={setAmount}
              setMemo={setMemo}
              memo={memo}
              setNote={setNote}
            />
          ),
          1: (
            <ManagePayees
              payeeLoading={getPayeeLoading}
              payees={payees}
              setIsPayeeOpen={setIsPayeeOpen}
              refetchPayees={refetchPayees}
              setPayeeData={setPayeeData}
              payeeData={payeeData}
              setIsEdit={setIsEdit}
              type={type}
              referenceId={
                type === "Program"
                  ? Program?.organization?.id ?? ""
                  : Group?.activeGroup?.id
              }
            />
          ),
        }[selectedTab]
      ) : (
        <AddPayeeContent
          payeeToAdd={payeeData}
          hasErrors={payeeErrors}
          setHasErrors={setPayeeErrors}
        />
      )}
    </CustomModal>
  );
}

export default SendCheck;
